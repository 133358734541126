import { useProvider } from '@celo-tools/use-contractkit';
import { Contract } from '@ethersproject/contracts';
import { useEffect, useState } from 'react';
import { getDeployment, NETWORK } from '..';
import { CeloApes } from '../../../nft-contracts/typechain';

export function useTotalSupply() {
  const [totalSypply, setTotalSupply] = useState('0');
  const deployment = getDeployment(NETWORK.chainId);
  const signer = useProvider();
  useEffect(() => {
    async function getSupply() {
      try {
        const cApe = (
          new Contract(deployment.address, deployment.abi, signer) as CeloApes
        ).connect(signer);
        setTotalSupply((await cApe.totalSupply()).toString());
      } catch (error) {
        console.error(error);
      }
    }

    getSupply();
    const interval = setInterval(() => getSupply(), 2000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signer]);
  return totalSypply;
}
