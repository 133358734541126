/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  ContractKitProvider,
  Alfajores,
  ChainId,
  Mainnet,
} from '@celo-tools/use-contractkit';
import { Interface } from '@ethersproject/abi';
import '@celo-tools/use-contractkit/lib/styles.css';

import AlfajoresDeployment from './deployments/alfajores/CeloApes.json';
import CeloDeployment from './deployments/celo/CeloApes.json';
import { Toaster } from 'react-hot-toast';

export interface Deployment {
  address: string;
  abi: Interface;
}
export const NETWORK =
  process.env.REACT_APP_NETWORK === 'celo' ? Mainnet : Alfajores;
console.log(
  `Running on ${NETWORK.name}, Contract Address - ${
    getDeployment(NETWORK.chainId).address
  }`
);

export function getDeployment(chainId: ChainId) {
  return chainId === ChainId.Mainnet ? CeloDeployment : AlfajoresDeployment;
}

ReactDOM.render(
  <React.StrictMode>
    <ContractKitProvider
      dapp={{
        name: 'Celo Apes Kingdom',
        description: 'The Official Miniting Website of Celo Apes',
        url: 'https://celoapes.club',
        icon: 'https://celoapes.club/favicon.ico',
      }}
      network={NETWORK}
      networks={[NETWORK]}
    >
      <Toaster
        position="top-right"
        toastOptions={{
          className: 'w-72 md:w-96',
          style: {
            padding: '0px',
          },
        }}
      />
      <App />
    </ContractKitProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
