import apesgif from './img/celo-apes-transparent.gif';
import logo from './img/ape-face.png';
import angelApe from './img/AngelApe.png';
import ariApe from './img/ariApe.png';
import MobiApe from './img/MobiApe.png';
import nomApe from './img/nomApe.png';
import PunkApe from './img/PunkApe.png';
import MoolaApe from './img/MoolaApe.png';
import DahliaApe from './img/DahliaApe.png';
import './App.css';
import MintButton from './components/MintButton';
import { useTotalSupply } from './hooks/useTotalSupply';
import { getDeployment, NETWORK } from '.';

function App() {
  const sold = useTotalSupply();

  return (
    <div className="App">
      <body
        data-aos-easing="ease-in-out"
        data-aos-duration="1000"
        data-aos-delay="0"
      >
        <header id="header" className="header fixed-top">
          <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
            <a href="#" className="logo d-flex align-items-center">
              <img src={logo} alt="Celo Apes Kindom" />
            </a>
            <nav id="navbar" className="navbar">
              <ul>
                <li>
                  <a
                    className="nav-link scrollto"
                    href="https://discord.gg/bxEAVV8Fcj"
                  >
                    Discord
                  </a>
                </li>
                <li>
                  <a
                    className="nav-link scrollto"
                    href="https://twitter.com/Celo_Apes"
                  >
                    Twitter
                  </a>
                </li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle"></i>
            </nav>
          </div>
        </header>
        <section id="hero" className="hero d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="text-center col-lg-6 hero-img aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <img src={apesgif} className="img-fluid" alt="Celo Apes" />
              </div>
              <div className="col-lg-6 d-flex flex-column justify-content-center">
                <p data-aos="fade-up" className="aos-init aos-animate">
                  Celo Apes Kingdom
                </p>
                <h1
                  data-aos="fade-up"
                  data-aos-delay="200"
                  className="aos-init aos-animate"
                >
                  Join the Kingdom!
                </h1>
                <h2
                  data-aos="fade-up"
                  data-aos-delay="400"
                  className="aos-init aos-animate"
                >
                  The Celo Apes Kingdom is an collection of{' '}
                  <strong>10000 APE NFT ({sold} minted!)</strong> on Celo
                  Blockchain. Own an Ape to become part of the most epic Ape
                  Kingdom. 10% of all money goes towards eradicating poverty.
                </h2>

                <div className="container flex-column justify-content-center align-center">
                  <div className="text-center text-lg-start mint-wrapper">
                    <MintButton />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <main id="main">
          <section id="showcase" className="showcase">
            <div className="container aos-init aos-animate" data-aos="fade-up">
              <header className="section-header">
                <h2>Legend Apes Of Celo</h2>
                <p>SHOWCASE</p>
              </header>
              <div
                className="showcase-slider swiper swiper-initialized swiper-horizontal swiper-pointer-events aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div
                  className="swiper-wrapper"
                  id="swiper-wrapper-3eeca746108c2f764"
                  aria-live="off"
                >
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                    role="group"
                    aria-label="1 / 14"
                    data-swiper-slide-index="0"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={MoolaApe}
                          className="showcase-img"
                          alt="NFT0001"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                    role="group"
                    aria-label="2 / 14"
                    data-swiper-slide-index="1"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={nomApe}
                          className="showcase-img"
                          alt="NFT0004"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                    role="group"
                    aria-label="3 / 14"
                    data-swiper-slide-index="2"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={PunkApe}
                          className="showcase-img"
                          alt="NFT0009"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    role="group"
                    aria-label="4 / 14"
                    data-swiper-slide-index="3"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={DahliaApe}
                          className="showcase-img"
                          alt="NFT0012"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    role="group"
                    aria-label="5 / 14"
                    data-swiper-slide-index="4"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={angelApe}
                          className="showcase-img"
                          alt="NFT0013"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    role="group"
                    aria-label="6 / 14"
                    data-swiper-slide-index="5"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={MobiApe}
                          className="showcase-img"
                          alt="NFT0015"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    role="group"
                    aria-label="7 / 14"
                    data-swiper-slide-index="6"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={ariApe}
                          className="showcase-img"
                          alt="NFT0016"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="swiper-slide swiper-slide-prev"
                    role="group"
                    aria-label="1 / 14"
                    data-swiper-slide-index="0"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={MoolaApe}
                          className="showcase-img"
                          alt="NFT0001"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-active"
                    role="group"
                    aria-label="2 / 14"
                    data-swiper-slide-index="1"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={nomApe}
                          className="showcase-img"
                          alt="NFT0004"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-next"
                    role="group"
                    aria-label="3 / 14"
                    data-swiper-slide-index="2"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={PunkApe}
                          className="showcase-img"
                          alt="NFT0009"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide"
                    role="group"
                    aria-label="4 / 14"
                    data-swiper-slide-index="3"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={DahliaApe}
                          className="showcase-img"
                          alt="NFT0012"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide"
                    role="group"
                    aria-label="5 / 14"
                    data-swiper-slide-index="4"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={angelApe}
                          className="showcase-img"
                          alt="NFT0013"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide"
                    role="group"
                    aria-label="6 / 14"
                    data-swiper-slide-index="5"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={MobiApe}
                          className="showcase-img"
                          alt="NFT0015"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide"
                    role="group"
                    aria-label="7 / 14"
                    data-swiper-slide-index="6"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={ariApe}
                          className="showcase-img"
                          alt="NFT0016"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                    role="group"
                    aria-label="1 / 14"
                    data-swiper-slide-index="0"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={MoolaApe}
                          className="showcase-img"
                          alt="NFT0001"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                    role="group"
                    aria-label="2 / 14"
                    data-swiper-slide-index="1"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={nomApe}
                          className="showcase-img"
                          alt="NFT0004"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                    role="group"
                    aria-label="3 / 14"
                    data-swiper-slide-index="2"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={PunkApe}
                          className="showcase-img"
                          alt="NFT0009"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    role="group"
                    aria-label="4 / 14"
                    data-swiper-slide-index="3"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={DahliaApe}
                          className="showcase-img"
                          alt="NFT0012"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    role="group"
                    aria-label="5 / 14"
                    data-swiper-slide-index="4"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={angelApe}
                          className="showcase-img"
                          alt="NFT0013"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    role="group"
                    aria-label="6 / 14"
                    data-swiper-slide-index="5"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={MobiApe}
                          className="showcase-img"
                          alt="NFT0015"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    role="group"
                    aria-label="7 / 14"
                    data-swiper-slide-index="6"
                  >
                    <div className="showcase-item">
                      <div className="profile">
                        <img
                          src={ariApe}
                          className="showcase-img"
                          alt="NFT0016"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
                  <span
                    className="swiper-pagination-bullet"
                    tabIndex={0}
                    role="button"
                    aria-label="Go to slide 1"
                  ></span>
                  <span
                    className="swiper-pagination-bullet swiper-pagination-bullet-active"
                    tabIndex={0}
                    role="button"
                    aria-label="Go to slide 2"
                  ></span>
                  <span
                    className="swiper-pagination-bullet"
                    tabIndex={0}
                    role="button"
                    aria-label="Go to slide 3"
                  ></span>
                  <span
                    className="swiper-pagination-bullet"
                    tabIndex={0}
                    role="button"
                    aria-label="Go to slide 4"
                  ></span>
                  <span
                    className="swiper-pagination-bullet"
                    tabIndex={0}
                    role="button"
                    aria-label="Go to slide 5"
                  ></span>
                  <span
                    className="swiper-pagination-bullet"
                    tabIndex={0}
                    role="button"
                    aria-label="Go to slide 6"
                  ></span>
                  <span
                    className="swiper-pagination-bullet"
                    tabIndex={0}
                    role="button"
                    aria-label="Go to slide 7"
                  ></span>
                </div>
                <span
                  className="swiper-notification"
                  aria-live="assertive"
                  aria-atomic="true"
                ></span>
              </div>
            </div>
          </section>
        </main>
        <footer id="footer" className="footer">
          <div className="container">
            <div className="copyright">
              ©{' '}
              <strong>
                <span>2021 CELO APES KINGDOM</span>
              </strong>
              . All Rights Reserved
              <br />
              Official Contract Address -
              <a
                href={`${NETWORK.explorer}/token/${
                  getDeployment(NETWORK.chainId).address
                }`}
              >
                <strong>{getDeployment(NETWORK.chainId).address}</strong>
              </a>
            </div>
          </div>
        </footer>
      </body>
    </div>
  );
}

export default App;
