/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  useContractKit,
  useGetConnectedSigner,
} from '@celo-tools/use-contractkit';
import { Contract, ContractReceipt } from '@ethersproject/contracts';
import { useEffect, useState } from 'react';
import { getDeployment, NETWORK } from '..';
import { CeloApes } from '../../../nft-contracts/typechain';
import * as toast from './Toast';
import { utils } from 'ethers';

export function MintButton() {
  const getConnectedSigner = useGetConnectedSigner();
  const { account, performActions, connect, destroy } = useContractKit();
  const [minting, setMinting] = useState(false);

  // disconnect on refresh
  useEffect(() => {
    if (account) destroy().then(console.log).catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deployment = getDeployment(NETWORK.chainId);
  async function mint(qty: number) {
    let reciept: ContractReceipt;
    try {
      setMinting(true);
      await performActions(async (kit) => {
        const signer = await getConnectedSigner();
        const balance = await signer.getBalance();
        const cApe = (
          new Contract(deployment.address, deployment.abi, signer) as CeloApes
        ).connect(signer);
        const price = await cApe.cost();
        const totalPrice = price.mul(qty);

        if (totalPrice.gte(balance)) {
          toast.warning(
            'Low CELO Balance!',
            `You are short of ${utils.formatEther(
              totalPrice.sub(balance).toString()
            )} CELO only. Hurry and topup your wallet to mint Apes!`
          );
          return;
        }

        reciept = await (
          await cApe.mint(await signer.getAddress(), qty, { value: totalPrice })
        ).wait();
        console.log(reciept);
        toast.success(
          'Horrah! You are now part of Kingdom',
          undefined,
          reciept.transactionHash
        );
      });
    } catch (error: any) {
      console.error(error);
      toast.error('Oops! Something went wrong', error.message);
    } finally {
      setMinting(false);
    }
  }

  return (
    <>
      {!account ? (
        <div className="dropdown d-inline-flex align-items-center justify-content-center align-self-center">
          <button
            className="btn btn-get-started"
            style={{ padding: '15px 40px', background: '#614b76' }}
            onClick={() => connect().catch(console.error)}
          >
            Connect
          </button>
        </div>
      ) : (
        <div className="dropdown d-inline-flex align-items-center justify-content-center align-self-center">
          <button
            disabled={minting}
            className="btn btn-get-started dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ padding: '15px 40px', background: '#614b76' }}
          >
            Mint
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a
                className="dropdown-item"
                onClick={() => mint(1).catch(console.error)}
              >
                1 Ape
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                onClick={() => mint(3).catch(console.error)}
              >
                3 Ape
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                onClick={() => mint(5).catch(console.error)}
              >
                5 Ape
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                onClick={() => mint(10).catch(console.error)}
              >
                10 Ape
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                onClick={() => mint(15).catch(console.error)}
              >
                15 Ape
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
}

export default MintButton;
